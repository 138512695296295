<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/services/5-1/1 (1).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (2).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (3).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (4).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (5).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (6).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (7).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (8).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (9).jpg">
					<img class="" src="@/assets/images/services/5-1/1 (10).jpg">
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"安全加固服务",
			content:"如何建立可信的信息安全环境，提升信息安全的保障水平，无论政府、企业还是个人都给予了前所未有的关注",
            cc:"随着信息技术和信息产业的迅猛发展，信息技术的应用领域逐渐从小型业务系统向大型、关键业务扩展，越来越多的基础性、全局性作用日益增强。",
			price:'在线咨询',
			url:require('../../assets/images/services/5-1/137_G_1529373390445.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







